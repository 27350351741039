//
// Social buttons
// --------------------------------------------------


// Base styles

.btn-social {
  display: inline-block;
  width: $btn-social-size;
  height: $btn-social-size;
  padding: 0;
  border: $btn-social-border-width solid transparent;
  transition: $btn-social-transition;
  @include border-radius($btn-social-border-radius);
  background-color: $btn-social-dark-bg;
  color: $btn-social-dark-color;
  font-size: $btn-social-font-size;
  text: {
    align: center;
    decoration: none !important;
  }
  > i {
    line-height: $btn-social-size - .125rem;
  }

  &:hover { color: $btn-social-dark-hover-color; }
  &:focus { outline: none; }
  &:active {
    transition: none;
    box-shadow: none !important;
  }

  &.bs-light {
    background-color: $btn-social-light-bg;
    color: $btn-social-light-color;
    &:hover {
      background-color: $btn-social-light-hover-bg !important;
      box-shadow: none !important;
    }
  }
}


// Sizing

.bs-lg {
  width: $btn-social-size-lg;
  height: $btn-social-size-lg;
  @include border-radius($btn-social-border-radius-lg);
  font-size: $btn-social-font-size-lg;
  > i {
    line-height: $btn-social-size-lg - .125rem;
  }
}
.bs-sm {
  width: $btn-social-size-sm;
  height: $btn-social-size-sm;
  @include border-radius($btn-social-border-radius-sm);
  font-size: $btn-social-font-size-sm;
  > i {
    line-height: $btn-social-size-sm - .125rem;
  }
}


// Outline variant

.bs-outline {
  border-color: $btn-social-dark-border-color;
  background-color: transparent;
  &.bs-light {
    border-color: $btn-social-light-border-color;
    background-color: transparent;
  }
  &:hover { border-color: transparent; }
}


// Round shape

.bs-round {
  border-radius: 50% !important;
}


// Brand colors

.bs-facebook:hover {
  background-color: #3b5998;
  &.bs-light { color: #3b5998; }
}
.bs-twitter:hover {
  background-color: #1da1f2;
  &.bs-light { color: #1da1f2; }
}
.bs-instagram:hover {
  background-color: #5851db;
  &.bs-light { color: #5851db; }
}
.bs-google:hover {
  background-color: #ea4335;
  &.bs-light { color: #ea4335; }
}
.bs-linkedin:hover {
  background-color: #0077b5;
  &.bs-light { color: #0077b5; }
}
.bs-pinterest:hover {
  background-color: #bd081c;
  &.bs-light { color: #bd081c; }
}
.bs-tumblr:hover {
  background-color: #35465c;
  &.bs-light { color: #35465c; }
}
.bs-behance:hover {
  background-color: #1769ff;
  &.bs-light { color: #1769ff; }
}
.bs-dribbble:hover {
  background-color: #ea4c89;
  &.bs-light { color: #ea4c89; }
}
.bs-vk:hover {
  background-color: #45668e;
  &.bs-light { color: #45668e; }
}
.bs-odnoklassniki:hover {
  background-color: #ed812b;
  &.bs-light { color: #ed812b; }
}
.bs-skype:hover {
  background-color: #00aff0;
  &.bs-light { color: #00aff0; }
}
.bs-hangouts:hover {
  background-color: #0f9d58;
  &.bs-light { color: #0f9d58; }
}
.bs-messenger:hover {
  background-color: #0084ff;
  &.bs-light { color: #0084ff; }
}
.bs-viber:hover {
  background-color: #59267c;
  &.bs-light { color: #59267c; }
}
.bs-telegram:hover {
  background-color: #0088cc;
  &.bs-light { color: #0088cc; }
}
.bs-youtube:hover {
  background-color: #ff0000;
  &.bs-light { color: #ff0000; }
}
.bs-figma:hover {
  background-color: #a259ff;
  &.bs-light { color: #a259ff; }
}
.bs-vimeo:hover {
  background-color: #1ab7ea;
  &.bs-light { color: #1ab7ea; }
}
.bs-wechat:hover {
  background-color: #7bb32e;
  &.bs-light { color: #7bb32e; }
}
.bs-github:hover {
  background-color: #4078c0;
  &.bs-light { color: #4078c0; }
}
.bs-gitlab:hover {
  background-color: #fc6d26;
  &.bs-light { color: #fc6d26; }
}
.bs-paypal:hover {
  background-color: #003087;
  &.bs-light { color: #003087; }
}
.bs-slack:hover {
  background-color: #3eb991;
  &.bs-light { color: #3eb991; }
}
.bs-trello:hover {
  background-color: #0079bf;
  &.bs-light { color: #0079bf; }
}
.bs-twitch:hover {
  background-color: #6441a5;
  &.bs-light { color: #6441a5; }
}
.bs-rss:hover {
  background-color: #f26522;
  &.bs-light { color: #f26522; }
}
.bs-email:hover {
  background-color: $primary;
  &.bs-light { color: $primary; }
}
